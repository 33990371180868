import React from 'react'
import styles from './Sidebar.module.css'
import monument from '../../img/monument.png'

const Sidebar = () => {
    return (
        <div className={styles.sideBar}>
            <img src={monument} className={styles.monument}/>

            <div className={styles.textBlock}>
                <h1>Верни герою имя</h1>
                <h2>
                    Юнармейцы помнят и чтят подвиг героев, которые сражались за свободу и
                    независимость нашей страны.
                </h2>
            </div>
        </div>
    )
}

export default Sidebar