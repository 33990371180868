import React from 'react'
import styles from './Search.module.css'

import regList from '../../../data/regList.json'

const Search = ({item}) => {
 
    return (
        <div className={styles.search}>
            <div>                
                {item?.type_obj == '1' ?
                <>
                    <div className={styles.searchName}>
                    <span className={styles.searchTitle}>
                    <div className={styles.model1}></div>
                    Закрепленный отряд</span>
                    {item?.search}
                    </div>
                    <div className={styles.searchName}>Площадь: <b style={{color: 'brown'}}>{item.square || '-'}</b></div>
                    <div className={styles.searchName}>Адрес: <b style={{color: 'brown'}}>{item.address || '-'}</b></div>
                </>
                :
                <>
                <div className={styles.searchName}>
                    <span className={styles.searchTitle}>
                    <div className={styles.model1}></div>
                    КТО НАШЕЛ</span>
                    {item
                        ?.search}
                </div>
                {item
                    ?.department_chief || item
                        ?.department_email
                            ? <div className={styles.searchName}>Руководитель отряда: <b
                                        style={{
                                        color: 'brown'
                                    }}>
                                        {item
                                            ?.department_chief || 'Нет'}</b>
                                    <b
                                        style={{
                                        color: 'brown'
                                    }}>
                                        {item
                                            ?.department_chief && item
                                                ?.department_email
                                                    ? '|'
                                                    : <></>}
                                        {item
                                            ?.department_email}</b><br/>
                                </div>
                            : <></>}
                </>
                }
            </div>

            <div className={styles.searchRegion}>
                <div style={{width: '130px'}}>
                    {regList.find(el => el.id == item.region_id)?.regionName}{` `}
                    {regList.find(el => el.id == item.region_id)?.federalUnit}
                </div>
                <div
                    className={styles.flag}
                    style={{backgroundImage: `url(/flags/${regList.find(el => el.id == item.region_id)?.flag}.png)`}}>
                </div>
            </div>
        </div>
    )
}

export default Search