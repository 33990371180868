import React from 'react'
import styles from './Header.module.css'
import Search from './Search'

const Header = ({mapDataСountry, handleSelect, item, setIsPopup, setItem}) => {
  return (
    <>
        <div className={styles.title}>Союзное государство</div>
        <div className={styles.selectBlock}>

        {mapDataСountry == 'RU' && <Search item={item} setIsPopup={setIsPopup} setItem={setItem} mapDataСountry={mapDataСountry}/>}

            <div
                className={styles.buttonCountry}
                style={{
                    background: mapDataСountry === 'RU' ? '#af0017' : '#fff',
                    color: mapDataСountry === 'RU' ? '#fff' : '#af0017'
                }}
                onClick={() => handleSelect('RU')}
            >
                Российская Федерация
            </div>
            <div
                className={styles.buttonCountry}
                style={{
                    background: mapDataСountry === 'BY' ? '#af0017' : '#fff',
                    color: mapDataСountry === 'BY' ? '#fff' : '#af0017'
                }}
                onClick={() => handleSelect('BY')}
            >
                Республика Беларусь
            </div>
            
        </div>
    </>
  )
}

export default Header