import React from 'react'
import styles from "./PopupList.module.css"
import regList from '../../data/regList.json'

const PopupList = ({ position, setPosition, handlePopupList, heroesList }) => {

    const getRegName = (id) => {
        const element = regList?.find(el => el.id == id);
        return `${element?.regionName} ${element?.federalUnit}`;
    }

    return (
        <div
            className={styles.popupListWrap}
            onClick={() => setPosition({id: null, plotX: 0, plotY: 0, list: []})}>
            <div
                className={styles.popupList}
                style={{
                position: 'absolute',
                zIndex: 111,
                top: `${position
                    ?.plotY}px`,
                left: `${position.plotX}px`
            }}>
                <div className={styles.list}>
                    <div>Регион:
                        <bold>{getRegName(position.list[0].region)}</bold>
                    </div>
                    <div className={styles.title}>Герои:</div>
                    {position
                        .list
                        .filter(el => el.type_obj == '0')
                        .map(el => <span key={el.id} onClick={() => handlePopupList(el)}>{el.heroName || 'Имя неизвестно'}</span>)}
                    <div className={styles.title}>Мемориальные объекты:</div>
                    {position
                        .list
                        .filter(el => el.type_obj == '1')
                        .map(el => <span key={el.id} onClick={() => handlePopupList(el)}>{el.heroName || 'Имя неизвестно'}</span>)}
                </div>
            </div>
        </div>
    )
}

export default PopupList