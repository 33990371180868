import React, {useState, useEffect} from 'react'

import styles from "./Info.module.css"
import ReactQuill from "react-quill";

import Header from './Header/Header';
import Search from './Search/Search';
import Line from './Line/Line';
import Images from './Images/Images';
import 'react-quill/dist/quill.snow.css';

import qrcode from '../../img/qr_code.png'

import {AiOutlineCloseCircle} from "react-icons/ai";

const Info = ({id, setIsPopup}) => {
    const [item,
        setItem] = useState({});
    const [image,
        setImage] = useState(null);

    async function logJSONData() {
        const response = await fetch(`https://asmc.yunarmy.ru/iapi/v1.0/get_hero/${id}`);
        const jsonData = await response.json();
        setItem(jsonData.Hero[0]);
    }

    async function getImage() {
        const response = await fetch(`https://asmc.yunarmy.ru/iapi/v1.0/hero_name/${id}/image`);
        const jsonData = await response.json();
        setImage(jsonData
            ?.files, 'jsonData');
    }

    useEffect(() => {
        logJSONData();
    }, []);

    useEffect(() => {
        item
            ?.id && getImage(item
                ?.id);
    }, [item
            ?.id])

    document.addEventListener("keydown", (e) => {
        if (e.code == "Escape") {
            setIsPopup(0);
        }
    });

    return (
        <div className={styles.popup}>
            <div className={styles.popupInner}>
                <div className={styles.main}>
                    <Header/>
                    <Search item={item}/>
                    <Line/>

                    <div className={styles.heroBlock}>
                        <div className={styles.heroInfo}>

                            <Images data={image}/>

                            <div className={styles.heroName}>
                                {item.type}
                            </div>
                            <div className={`${styles.tagList} ${styles.tagListMob}`}>
                                <span className={styles.tagItemBlue}>#ЮНАРМИЯ</span>

                                <span className={styles.tagItemRed}>#ВЕРНИГЕРОЮИМЯ</span>

                                <span className={styles.tagItemBlue}>#ПАМЯТЬЖИВА</span>

                                <span className={styles.tagItemRed}>#ЮНАРМИЯ</span>

                                <span className={styles.tagItemBlue}>#ВЕРНИГЕРОЮИМЯ</span>

                                <span className={styles.tagItemRed}>#ЮНПАТРИОТ</span>

                            </div>
                        </div>

                        <div className={styles.heroTextBlock}>
                            <div className={styles.content}>
                                <div className={styles.heroText1}>
                                    <ReactQuill
                                        style={{
                                        width: '100%'
                                    }}
                                        toolbar={false}
                                        value={item
                                        ?.description || ''}
                                        readOnly={true}
                                        onChange={(e) => setItem((prev) => ({
                                        ...prev,
                                        description: e
                                    }))}/>
                                </div>
                            </div>

                            <div className={styles.dopInfo}>
                                <div className={`${styles.tagList} ${styles.tagListDeck}`}>
                                    <span className={styles.tagItemBlue}>#ЮНАРМИЯ</span>

                                    <span className={styles.tagItemRed}>#ВЕРНИГЕРОЮИМЯ</span>

                                    <span className={styles.tagItemBlue}>#ПАМЯТЬЖИВА</span>

                                    <span className={styles.tagItemRed}>#ЮНАРМИЯ</span>

                                    <span className={styles.tagItemBlue}>#ВЕРНИГЕРОЮИМЯ</span>

                                    <span className={styles.tagItemRed}>#ЮНПАТРИОТ</span>

                                </div>

                                <i className={styles.qrcode}><img src={qrcode}/></i>
                            </div>
                        </div>
                    </div>
                    <i onClick={() => setIsPopup(0)} className={styles.back}><AiOutlineCloseCircle size='2rem'/></i>

                </div>
            </div>
        </div>
    )
}

export default Info