import React from 'react'
import styles from './index.module.css'
import ReactSelect from 'react-select'
import regList from '../../../data/regList.json'

const Index = ({item, setItem, mapDataСountry}) => {

    return (
        <div className={styles.container}>

            <ReactSelect
                style={{
                fontSize: '14px'
            }}
                placeholder="Выберите регион..."
                value={{
                value: item
                    ?.id,
                label: `${ [
                    {
                        value: 0,
                        regionName: 'Выбрать',
                        federalUnit: ''}, ...regList]?.find(el => el?.id == item?.id)?.regionName + ' ' + [{value: 0, regionName: 'Выбрать', federalUnit: ''}, ...regList]?.find(el => el?.id == item?.id)?.federalUnit}` || {
                            value: 0,
                            regionName: 'Выбрать',
                            federalUnit: ''
                        }
                    }}
                options={[
                {
                    value: 0,
                    regionName: 'Выбрать',
                    federalUnit: ''
                },
                ...regList
            ].map((i) => ({
                label: `${i
                    ?.regionName + ' ' + i
                        ?.federalUnit}`,
                value: i.id
            }))}
                onChange={e => setItem(prev => ({
                ...prev,
                id: e
                    ?.value
            }))}
                noOptionsMessage={(e) => 'Ничего не найдено'}
                onInputChange={(e) => setItem(e)}/></div>
    )
}

export default Index