import React from 'react'
import styles from './Line.module.css'

const Line = () => {
  return (
    <div className={styles.lineBlock}>
        <div className={styles.lines}>
            <div className={styles.bigLine}></div>
            <div className={styles.smallLine}></div>
        </div>

        <div className={styles.heroWord}>
            <div className={styles.model2}></div>
            МЕМОРИАЛЬНЫЙ ОБЪЕКТ</div>
    </div>
  )
}

export default Line