import React from 'react'
import styles from './Header.module.css'
import min_logo from '../../../img/min_logo.png'
import youn_logo from '../../../img/yun_logo.png'

const Header = () => {
  return (
    <>
    <div className={styles.model_line1}></div>
    <div className={styles.model_line2}></div>
    <div className={styles.model_line3}></div>
    <div className={styles.model_line4}></div>
    <div className={styles.header}>
        <div className={styles.icons}>
            <i className={styles.min_logo}><img src={min_logo}/></i>
            <i className={styles.youn_logo}><img src={youn_logo}/></i>
        </div>

        <div className={styles.titles}>
            <h1 className={styles.mainTitle}>«ВЕРНИ ГЕРОЮ ИМЯ»</h1>
            <div className={styles.sideTitle}>ВСЕРОССИЙСКАЯ<br/>
                ЮНАРМЕЙСКАЯ<br/>
                АКЦИЯ</div>
        </div>
    </div>
    </>
  )
}

export default Header