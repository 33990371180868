import React, {useEffect, useState} from 'react'
import styles from './Images.module.css'
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';

const Images = ({
    data
}) => {
    const [photos, setPhotos] = useState(null); 
    const getNormolizedPhotos = (data = []) => { //преобразование данных
        const arr = data.map((photo, i) => ({
            ...photo,
            isActive: i === 0
                ? true
                : false
        }));
        return arr;
    }

    useEffect(() => { //получение данных
        if (!data) 
            return setPhotos(null);
        
        setPhotos(getNormolizedPhotos(data));
    }, [data]);

    const handleSlide = (type) => { //смена фото
      const currentActiveIndex = photos.findIndex(el => el.isActive === true);
    
      if (type === 'left') {
        if (currentActiveIndex === 0) return;
    
        const newPhotos = photos.map((el, i) => ({
          ...el,
          isActive: i === currentActiveIndex - 1
        }));
    
        setPhotos(newPhotos);
      } else if (type === 'right') {
        if (currentActiveIndex === photos.length - 1) return;
    
        const newPhotos = photos.map((el, i) => ({
          ...el,
          isActive: i === currentActiveIndex + 1
        }));
    
        setPhotos(newPhotos);
      }
    };

    return (
        <i classname={styles.heroPhoto}>
            {photos
                ? <div className={styles.slider}>
                  {
                    photos.length > 1 && 
                    <>
                      <span onClick={() => handleSlide('left')} className={styles.arrowLeft}><AiOutlineArrowLeft/></span>
                      <span onClick={() => handleSlide('right')} className={styles.arrowRight}><AiOutlineArrowRight/></span>
                    </>  
                  }
                {
                    photos.map(photo => <img
                        key={photo.id}
                        src={`https://asmc.yunarmy.ru${photo.path_file}`}
                        className={styles[photo.isActive ? 'activeHeroPhoto' : 'heroPhoto']}/>)
                } 
                
                {photos.length > 1 && 
                <div className={styles.points}>
                  {photos.map(point => <div className={styles[point.isActive ? 'activePoint' : 'point']} key={point.id}></div>)}
                </div>}
                
                </div>
            
            : <div className={styles.withOutPhoto}>Без фото</div >}
        </i>
    )
}

export default Images