export const keys = [
    [
        "ru-arr", ''
    ],
    [
        "by-hm", ''
    ],
    [
        "by-br", ''
    ],
    [
        "by-ho", ''
    ],
    [
        "by-vi", ''
    ],
    [
        "by-hr", ''
    ],
    [
        "by-ma", ''
    ],
    [
        "by-mi", ''
    ],
    [
        "ru-hers", ''
    ],
    [
        "ru-za", ''
    ],
    [
        "ru-dn", ''
    ],
    [
        "ru-lh", ''
    ],
    [
        "ru-sc", ''
    ],
    [
        "ru-2509", ''
    ],
    [
        "ru-2485", ''
    ],
    [
        "ru-ck", ''
    ],
    [
        "ru-nn", ''
    ],
    [
        "ru-yn", ''
    ],
    [
        "ru-ky", ''
    ],
    [
        "ru-sk", ''
    ],
    [
        "ru-kh", ''
    ],
    [
        "ru-sl", ''
    ],
    [
        "ru-ka", ''
    ],
    [
        "ru-kt", ''
    ],
    [
        "ru-2510", ''
    ],
    [
        "ru-rz", ''
    ],
    [
        "ru-sa", ''
    ],
    [
        "ru-ul", ''
    ],
    [
        "ru-om", ''
    ],
    [
        "ru-ns", ''
    ],
    [
        "ru-mm", ''
    ],
    [
        "ru-ln", ''
    ],
    [
        "ru-sp", ''
    ],
    [
        "ru-ki", ''
    ],
    [
        "ru-kc", ''
    ],
    [
        "ru-in", ''
    ],
    [
        "ru-kb", ''
    ],
    [
        "ru-no", ''
    ],
    [
        "ru-st", ''
    ],
    [
        "ru-sm", ''
    ],
    [
        "ru-ps", ''
    ],
    [
        "ru-tv", ''
    ],
    [
        "ru-vo", ''
    ],
    [
        "ru-iv", ''
    ],
    [
        "ru-ys", ''
    ],
    [
        "ru-kg", ''
    ],
    [
        "ru-br", ''
    ],
    [
        "ru-ks", ''
    ],
    [
        "ru-lp", ''
    ],
    [
        "ru-ms", ''
    ],
    [
        "ru-ol", ''
    ],
    [
        "ru-nz", ''
    ],
    [
        "ru-pz", ''
    ],
    [
        "ru-vl", ''
    ],
    [
        "ru-vr", ''
    ],
    [
        "ru-ko", ''
    ],
    [
        "ru-sv", ''
    ],
    [
        "ru-bk", ''
    ],
    [
        "ru-ud", ''
    ],
    [
        "ru-mr", ''
    ],
    [
        "ru-cv", ''
    ],
    [
        "ru-cl", ''
    ],
    [
        "ru-ob", ''
    ],
    [
        "ru-sr", ''
    ],
    [
        "ru-tt", ''
    ],
    [
        "ru-to", ''
    ],
    [
        "ru-ty", ''
    ],
    [
        "ru-ga", ''
    ],
    [
        "ru-kk", ''
    ],
    [
        "ru-cn", ''
    ],
    [
        "ru-kl", ''
    ],
    [
        "ru-da", ''
    ],
    [
        "ru-ro", ''
    ],
    [
        "ru-bl", ''
    ],
    [
        "ru-tu", ''
    ],
    [
        "ru-ir", ''
    ],
    [
        "ru-ct", ''
    ],
    [
        "ru-yv", ''
    ],
    [
        "ru-am", ''
    ],
    [
        "ru-tb", ''
    ],
    [
        "ru-tl", ''
    ],
    [
        "ru-ng", ''
    ],
    [
        "ru-vg", ''
    ],
    [
        "ru-kv", ''
    ],
    [
        "ru-me", ''
    ],
    [
        "ru-ke", ''
    ],
    [
        "ru-as", ''
    ],
    [
        "ru-pr", ''
    ],
    [
        "ru-mg", ''
    ],
    [
        "ru-bu", ''
    ],
    [
        "ru-kn", ''
    ],
    [
        "ru-kd", ''
    ],
    [
        "ru-ku", ''
    ],
    [
        "ru-al", ''
    ],
    [
        "ru-km", ''
    ],
    [
        "ru-pe", ''
    ],
    [
        "ru-ad", ''
    ],
    ['ru-kr', '']
];